import { render, staticRenderFns } from "./MacOS.vue?vue&type=template&id=2aa6b522&scoped=true"
var script = {}
import style0 from "./MacOS.vue?vue&type=style&index=0&id=2aa6b522&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa6b522",
  null
  
)

export default component.exports