import { render, staticRenderFns } from "./Windows.vue?vue&type=template&id=3279efcb&scoped=true"
var script = {}
import style0 from "./Windows.vue?vue&type=style&index=0&id=3279efcb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3279efcb",
  null
  
)

export default component.exports