<template lang="">
    <div>
    <img :src="require('@/assets/bio-image.jpg') " />
        Hi im srikanth wanna play?
    </div>
</template>
<script>
export default {
    name: 'Test'
}
</script>
<style lang="">
    
</style>