<template lang="">
    <div class="bio-container" >
       <img :src="require('@/assets/images/bio.jpg')" alt="bio" height="100%" width="100%"/> 
    </div>
</template>
<script>
export default {
    name:'Bio'
}
</script>
<style >

</style>